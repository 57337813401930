<template>
    <div>
        <div class="row justify-content-center px-5">
            <div class="col-md-4 px-1">
                <b-progress
                    height="0.4em"
                    value="100"
                    variant="primary"
                    :style="`opacity: ${step === steps.roleStep ? 0.5 : 1}`"
                ></b-progress>
            </div>
            <div class="col-md-4 px-1">
                <b-progress
                    height="0.4em"
                    :value="step === steps.communicationStep ? 100 : 0"
                    variant="primary"
                    :style="`opacity: ${step === steps.communicationStep ? 0.5 : 1}`"
                ></b-progress>
            </div>
            <div v-if="onPublishing || goPremium" class="col-md-4 px-1">
                <b-progress
                    height="0.4em"
                    :value="step === steps.publishOrGoPremiumStep ? 100 : 0"
                    variant="primary"
                    :style="`opacity: ${step === steps.publishOrGoPremiumStep ? 0.5 : 1}`"
                ></b-progress>
            </div>
        </div>

        <div v-if="step === steps.roleStep" class="mt-3">
            <h6 class="font-weight-bold text-center">
                Almost there! Just two quick questions
                <br />
                to customize your experience:
            </h6>

            <div class="row justify-content-center">
                <div class="col-md-9">
                    <p class="mt-4 f-14 text-muted">I use My Worksheet Maker as:</p>
                </div>
            </div>

            <div class="row justify-content-center pb-5">
                <div class="col-md-8 mb-3 d-flex">
                    <div class="col pl-0 pr-2">
                        <button
                            class="btn btn-sm btn-block"
                            :class="{
                                'btn-primary': !role,
                                'btn-secondary': role !== null && role !== 't',
                                'btn-success': role === 't',
                            }"
                            @click="setRole('t')"
                        >
                            Teacher
                        </button>
                    </div>
                    <div class="col pr-0 pl-2">
                        <button
                            class="btn btn-sm btn-block"
                            :class="{
                                'btn-primary': !role,
                                'btn-secondary': role !== null && role !== 'h',
                                'btn-success': role === 'h',
                            }"
                            @click="setRole('h')"
                        >
                            Homeschooler
                        </button>
                    </div>
                </div>
                <div class="col-md-8 mb-3 d-flex">
                    <div class="col pl-0 pr-2">
                        <button
                            class="btn btn-sm btn-block"
                            :class="{
                                'btn-primary': !role,
                                'btn-secondary': role !== null && role !== 'p',
                                'btn-success': role === 'p',
                            }"
                            @click="setRole('p')"
                        >
                            Parent
                        </button>
                    </div>
                    <div class="col pr-0 pl-2">
                        <button
                            class="btn btn-sm btn-block"
                            :class="{
                                'btn-primary': !role,
                                'btn-secondary': role !== null && role !== 'o',
                                'btn-success': role === 'o',
                            }"
                            @click="setRole('o')"
                        >
                            Other
                        </button>
                        <div v-if="role === 'o'" class="form-group mt-2">
                            <input
                                id="otherRole-input"
                                v-model="otherRole"
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Please specify"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="role && role === 'o'" class="text-center">
                <button class="btn btn-secondary btn-sm mt-3" @click="submitOtherRole">Done</button>
            </div>
        </div>
        <div v-if="step === steps.communicationStep" class="mt-3">
            <h6 class="font-weight-bold text-center">Next, when do you want to hear from us?</h6>

            <div class="row justify-content-center">
                <div class="col-md-10">
                    <p class="mt-4 f-14 text-muted">
                        Do you want to recieve infrequent emails with special offers, tips, new features, and planned
                        maintenance?
                    </p>
                </div>
            </div>

            <div class="row justify-content-center align-items-center flex-column pb-5">
                <b-form-checkbox v-model="transactionalEmails" disabled class="font-weight-bold">
                    Account emails, passwords resets, receipts
                </b-form-checkbox>

                <b-form-checkbox v-model="marketingEmails" :value="true" class="mt-2 font-weight-bold">
                    Yes, send me occasional offers and tips
                </b-form-checkbox>
            </div>

            <div class="text-center">
                <button
                    data-test-id="finish-onboarding-button"
                    class="btn btn-success btn-sm mt-3 px-5 py-2"
                    @click="checkout"
                >
                    {{ onPublishing || goPremium ? 'Next' : 'Finish' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { debounce } from 'lodash'
import UserEvent from '../objects/UserEvent'
import Common from '../mixins/Common'
import StatsigHelper from '../mixins/StatsigHelper'

interface Data {
    steps: {
        roleStep: number
        communicationStep: number
        publishOrGoPremiumStep: number
    }
    role: string | null
    step: number
    transactionalEmails: boolean
    marketingEmails: boolean
    otherRole: string
    debouncedTrackingEvents: Record<string, Function>
}

export default defineComponent({
    name: 'OnboardingComponent',
    mixins: [Common, StatsigHelper],
    data(): Data {
        return {
            steps: {
                roleStep: 1,
                communicationStep: 2,
                publishOrGoPremiumStep: 3,
            },
            role: null,
            step: 1,
            transactionalEmails: true,
            marketingEmails: false,
            otherRole: '',
            debouncedTrackingEvents: {},
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasPlan: 'user/hasPlan',
            worksheet: 'document/worksheet',
            isWorksheet: 'document/isWorksheet',
            hasPlans: 'subscription/hasPlans',
            onPublishing: 'document/onpublishing',
            goPremium: 'document/goPremium',
        }),
        ...mapState({
            user: 'user',
            document: 'document',
            subscription: 'subscription',
        }),
    },
    watch: {
        async step() {
            await this.handleUserEventTracking()
        },
    },
    created() {
        if (this.user.user.occupation) {
            this.step = this.steps.communicationStep
        }

        this.createDebouncedTrackingEvents()

        this.marketingEmails =
            this.user.user.request_no_email === null ? this.user.user.request_no_email : !this.user.user.request_no_email
    },
    async mounted() {
        await this.check()
        await this.handleUserEventTracking()
    },
    methods: {
        async submitOtherRole() {
            await this.$logTrackingEvent(UserEvent.USER_ROLE_SELECTED, this.otherRole)
            await this.$store.dispatch('user/updateUser', { occupation: this.otherRole })
            this.step = this.steps.communicationStep
        },
        async setRole(role: string) {
            this.role = role

            if (role === 'o') {
                return
            }

            await this.$logTrackingEvent(UserEvent.USER_ROLE_SELECTED, role)
            await this.$store.dispatch('user/updateUser', { occupation: role })
            this.step = this.steps.communicationStep
        },
        async checkout() {
            this.$bvModal.hide('onboarding-modal')

            //simple implementation ::Replace with abtests check instead

            await this.$store.dispatch('user/updateUser', {
                request_no_email: !this.marketingEmails,
            })

            this.step = this.steps.publishOrGoPremiumStep
            localStorage.setItem(UserEvent.ONBOARDING_PROCESS.name, this.step.toString())
            if (this.onPublishing) {
                await this.showPrintingPaywall(this.onPublishing)
            } else if (this.goPremium) {
                this.$bvModal.show('checkout-modal')
            }
        },
        async check() {
            const onboardingProcess = localStorage.getItem(UserEvent.ONBOARDING_PROCESS.name)

            if (onboardingProcess) {
                this.step = parseInt(onboardingProcess)
            }

            if (this.step === this.steps.publishOrGoPremiumStep) {
                await this.showPrintingPaywall(this.onPublishing)
                this.$bvModal.hide('onboarding-modal')
            }
        },
        createDebouncedTrackingEvents() {
            this.debouncedTrackingEvents = {
                [UserEvent.SEEN_USER_ROLE_OVERLAY.name]: debounce(
                    async () => await this.$logTrackingEvent(UserEvent.SEEN_USER_ROLE_OVERLAY),
                    2000,
                ),
                [UserEvent.SEEN_USER_COMMUNICATION_OVERLAY.name]: debounce(
                    async () => await this.$logTrackingEvent(UserEvent.SEEN_USER_COMMUNICATION_OVERLAY),
                    2000,
                ),
            }
        },
        async handleUserEventTracking() {
            if (this.step === this.steps.roleStep) {
                this.debouncedTrackingEvents[UserEvent.SEEN_USER_ROLE_OVERLAY.name]()
            }

            if (this.step === this.steps.communicationStep) {
                this.debouncedTrackingEvents[UserEvent.SEEN_USER_COMMUNICATION_OVERLAY.name]()
            }
        },
    },
})
</script>

<style scoped>
.close-icon {
    top: 5px;
    right: 5px;
    height: 24px;
    width: 24px;
    z-index: 2;
}
</style>
